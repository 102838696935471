import React from 'react';
import { Grid } from '@mui/material';
import { CtaBannerContent } from '@models/column';
import { CMSLink } from '@components/CMSLink';
import { ShortArrow } from '@components/IconComponents/ShortArrow';
import { Picture } from '@components/Picture';
import { RichTextContent } from '@components/RichTextContent';
import { breakpoints } from '@web-for-marketing/react-ui';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { ctaBannerCarouselStyles as classes } from './styleObjects/CtaBannerCarouselSyles';

interface CtaBannerProps {
    banner: CtaBannerContent;
    lazyLoad?: boolean;
    isHidden?: boolean;
    trackingClass?: string;
}

export function CtaBanner({ banner, lazyLoad, isHidden, trackingClass }: CtaBannerProps): JSX.Element {
    const { title, description, linkText, linkUrl, ariaLabel, imagePath, mobileImagePath, imageAlt, mobileAlt } =
        banner;

    return (
        <>
            <Picture
                imagePath={imagePath}
                mobilePath={mobileImagePath}
                mobileQuery={breakpoints.md}
                alt={imageAlt}
                mobileAlt={mobileAlt}
                css={classes.bannerImage}
                lazyLoad={lazyLoad}
            />
            <Grid container alignItems='flex-end' css={classes.bannerContentContainer}>
                <Grid container item direction='column' rowSpacing={3} lg={10} md={10} xs={12}>
                    <Grid item>
                        <Heading component='h2' color='inversePrimary' css={classes.title}>
                            {title}
                        </Heading>
                    </Grid>
                    <Grid item>
                        <RichTextContent
                            color='inversePrimary'
                            content={description}
                            hideLinks={isHidden}
                            css={classes.description}
                        />
                    </Grid>
                    <Grid item alignSelf='flex-start'>
                        <CMSLink
                            href={linkUrl}
                            aria-label={ariaLabel}
                            target='_blank'
                            tabIndex={isHidden ? -1 : 0}
                            className={trackingClass}
                        >
                            <Text weight='bold' color='inversePrimary' css={classes.linkText}>
                                {linkText} <ShortArrow css={classes.linkArrow} />
                            </Text>
                        </CMSLink>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
