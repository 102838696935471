import { v2Colors, getRgbaColor, breakpoints, shadows } from '@web-for-marketing/react-ui';

export const LeadShareStyle = {
    container: {
        background: v2Colors.core.cloud,
    },
    containerNoColor: {
        background: v2Colors.core.snow,
    },
    media: {
        height: '100%',
        objectFit: 'fill',
    },
    videoMedia: {
        paddingTop: '56.25%',
        width: '100%',
        height: 0,
        position: 'relative',
        marginTop: '2rem',
        marginBottom: '3rem',
        '& > iframe': {
            height: '100%',
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            width: 'calc(100% - 7.2rem)',
            paddingTop: 'calc(56.25% - 3rem)',
        },
    },
    card: {
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        borderRadius: 10,
        backgroundColor: v2Colors.core.snow,
        display: 'flex',
        alignItems: 'center',
        border: 'solid 1px #DFE3EA',
        boxShadow: `0 0 0 ${getRgbaColor(v2Colors.core.slate, 0.1)}, 0 0 0 ${getRgbaColor(v2Colors.core.slate, 0.1)}`,
        transition: 'transform ease 0.75s, box-shadow ease 500ms',
        '&:hover': {
            boxShadow: `0 0 6px ${getRgbaColor(v2Colors.core.slate, 0.1)}, 0 10px 10px ${getRgbaColor(
                v2Colors.core.slate,
                0.1
            )}`,
            transition: 'transform ease 750ms, box-shadow ease 750ms',
            transform: 'translateY(-10px)',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            margin: '0 auto',
            flexDirection: 'column',
            '&:hover': {
                transform: 'none',
            },
        },
    },
    reverseCard: {
        flexDirection: 'row-reverse',
    },
    cardParentContainer: {
        padding: '2rem 0',
    },
    titles: {
        color: v2Colors.core.geotab,
        textAlign: 'center',
        maxWidth: '72rem',
        margin: '0 auto 1rem',
    },
    cardContainer: {
        minHeight: '36rem',
        width: '100%',
        marginBottom: '3rem',
        boxSizing: 'border-box',
        [`@media (max-width: ${breakpoints.md})`]: {
            minHeight: 'initial',
            maxHeight: 'initial',
            height: 'auto',
            width: '100%',
            marginBottom: '3rem',
            boxSizing: 'border-box',
        },
    },
    cardImage: {
        minWidth: '48.718%',
        maxWidth: '48.718%',
        height: 'inherit',
        [`@media (max-width: ${breakpoints.md})`]: {
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '22rem',
        },
    },
    cardContent: {
        padding: '0 6rem 0 7rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            padding: '2rem 3rem 2rem 2rem',
        },
    },
    linkText: {
        position: 'absolute',
        bottom: '3rem',
        left: '3rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            bottom: '1.5rem',
        },
    },
    bodyText: {
        paddingBottom: '9.4rem',
    },
    noButtonBody: {
        paddingBottom: '4rem',
    },
    verticalContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 3,
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingTop: '4rem',
        },
    },
    arrowContainer: {
        display: 'flex',
        marginBottom: '1rem',
    },
    cardTitle: {
        fontWeight: '400',
    },
    cardLink: {
        marginTop: '2rem',
    },
    cardSubTitle: {
        marginBottom: '1rem',
        fontWeight: '500',
    },
    demoItemContainer: {
        textAlign: 'left',
        paddingTop: '2rem',
        paddingBottom: '2rem',
    },
    demoTextInnerContainer: {
        width: '100%',
    },
    demoFormContainer: {
        width: '100%',
        marginTop: '-30%',
        '@media (min-width: 1200px)': {
            marginTop: '-15%',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            marginTop: '0',
        },
    },
    demoFormInnerContainer: {
        width: '100%',
        boxShadow: `0 0 6px ${getRgbaColor(v2Colors.core.slate, 0.1)}, 0 10px 10px ${getRgbaColor(
            v2Colors.core.slate,
            0.1
        )}`,
        borderRadius: 10,
        backgroundColor: v2Colors.core.snow,
        textAlign: 'center',
        maxWidth: '60rem',
    },
    demoTextInput: {
        width: '100%',
        maxWidth: '700px',
        '& label.Mui-focused': {
            color: v2Colors.core.innovation,
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: v2Colors.core.innovation,
            },
            '&.Mui-focused fieldset': {
                borderColor: v2Colors.core.innovation,
            },
        },
    },
    textSubheader: {
        marginBottom: '3rem',
    },
    subTitle: {
        marginBottom: '1rem',
    },
    customFormContainer: {
        maxWidth: '60rem',
        padding: '3.6rem 3.6rem 4rem',
        boxShadow: shadows.soft,
        borderRadius: 10,
        backgroundColor: v2Colors.core.snow,
    },
} as const;
