import React, { useState } from 'react';
import { Grid2, Stack } from '@mui/material';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { Checkbox, Heading, Text } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { FlexAlignment, TitleBodyButtonsFormContent } from '@models/column';
import { TextColor, V2Button } from '@web-for-marketing/react-ui';
import { Widget } from './Widget';

interface TitleBodyButtonsProps {
    content: TitleBodyButtonsFormContent;
    textColor?: TextColor;
    headerSection?: boolean;
    horizontalAlignment: FlexAlignment;
}

const classes = {
    buttonWrapper: {
        maxWidth: '100% !important',
    },
    fullWidth: {
        width: '100%',
    },
    title: (fullWidth?: boolean) => ({
        maxWidth: fullWidth ? undefined : '60rem',
    }),
};

export function TitleBodyButtons({
    content,
    textColor,
    headerSection,
    horizontalAlignment,
}: TitleBodyButtonsProps): JSX.Element {
    const [checkBoxChecked, setCheckBoxChecked] = useState(false);

    return (
        <Stack alignItems={horizontalAlignment}>
            {content.label ? (
                <Text
                    variant='h5'
                    color={textColor || 'activePrimary'}
                    weight='medium'
                    css={{ marginBottom: '1.6rem' }}
                >
                    {content.label}
                </Text>
            ) : null}
            <Stack alignItems={horizontalAlignment} spacing={{ xs: '2rem', md: '2.4rem' }}>
                {content.title ? (
                    <Heading
                        css={classes.title(content.titleFullWidth)}
                        variant={headerSection ? 'h1' : 'h2'}
                        color={textColor || 'primary'}
                        data-testid='titleBodyButtonsColumn-title'
                        dangerouslySetInnerHTML={{
                            __html: ParseAbbreviations({
                                content: content.title,
                                stringFormat: true,
                            }),
                        }}
                    />
                ) : null}
                {content.body ? (
                    <RichTextContent
                        variant={headerSection ? 'h4' : 'body1'}
                        color={textColor || 'secondary'}
                        weight={headerSection ? 'regular' : undefined}
                        data-testid='titleBodyButtonsColumn-body'
                        content={ParseAbbreviations({
                            content: content.body,
                            stringFormat: true,
                        })}
                        listStyle={content.listStyle}
                    />
                ) : null}
                {content.widget ? <Widget widget={content.widget} /> : null}
                {content.requestConsent ? (
                    <div>
                        <Checkbox
                            checked={checkBoxChecked}
                            onChange={(event) => setCheckBoxChecked(event.target.checked)}
                            id={`${content.title || ''}consent-checkbox`}
                            label={
                                <Text
                                    variant='body1'
                                    color='tertiary'
                                    dangerouslySetInnerHTML={{
                                        __html: ParseAbbreviations({
                                            content: content.checkboxText,
                                            stringFormat: true,
                                        }),
                                    }}
                                />
                            }
                        />
                    </div>
                ) : null}
                {content.buttons && content.buttons.length ? (
                    <div css={classes.fullWidth}>
                        <Grid2 container spacing={3} justifyContent={horizontalAlignment}>
                            {content.buttons.map(
                                (
                                    {
                                        buttonText,
                                        buttonLinkPath,
                                        buttonType,
                                        buttonTarget,
                                        buttonClassName,
                                        ariaLabel,
                                        isPopout,
                                        popoutCustomSection,
                                        popoutDisplayMode,
                                        popoutBackgroundImage,
                                        params = [],
                                    },
                                    index
                                ) =>
                                    buttonText ? (
                                        <Grid2
                                            size={{ xs: 12, md: 'auto' }}
                                            css={classes.buttonWrapper}
                                            key={`${index}${buttonText}${popoutCustomSection}`}
                                        >
                                            {isPopout ? (
                                                <PopoutButton
                                                    text={buttonText}
                                                    variant={buttonType || 'primary'}
                                                    customSectionKey={popoutCustomSection}
                                                    displayMode={popoutDisplayMode}
                                                    backgroundImagePath={popoutBackgroundImage}
                                                    params={params}
                                                    aria-label={ariaLabel}
                                                    disabled={content.requestConsent && !checkBoxChecked}
                                                    css={classes.fullWidth}
                                                    isV2
                                                />
                                            ) : (
                                                <V2Button
                                                    component='a'
                                                    variant={buttonType || 'primary'}
                                                    href={buttonLinkPath}
                                                    target={getLinkTargetValue(buttonTarget)}
                                                    aria-label={ariaLabel}
                                                    className={buttonClassName}
                                                    data-testid='ButtonId'
                                                    disabled={content.requestConsent && !checkBoxChecked}
                                                    css={classes.fullWidth}
                                                >
                                                    {buttonText}
                                                </V2Button>
                                            )}
                                        </Grid2>
                                    ) : null
                            )}
                        </Grid2>
                    </div>
                ) : null}
            </Stack>
        </Stack>
    );
}
