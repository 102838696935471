import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const featureIconsStyles = {
    titles: {
        color: v2Colors.core.geotab,
        textAlign: 'center',
        maxWidth: '72rem',
        margin: '0 auto',
        marginBottom: '3rem !important',
        '@media (max-width: 640px)': {
            textAlign: 'left',
        },
    },
    bodies: {
        marginBottom: '4rem !important',
        textAlign: 'center',
    },
    media: {
        height: '7.5rem',
        marginBottom: '1rem !important',
    },
    leftImage: {
        width: 'auto',
    },
    centerImage: {
        width: 'auto',
        display: 'block',
        margin: '0 auto',
    },
    rightImage: {
        width: 'auto',
        display: 'block',
        float: 'right',
    },
    leftHeading: {
        textAlign: 'left',
        clear: 'both',
    },
    centerHeading: {
        textAlign: 'center',
        clear: 'both',
    },
    rightHeading: {
        textAlign: 'right',
        clear: 'both',
    },
    leftBody: {
        textAlign: 'left',
    },
    centerBody: {
        textAlign: 'center',
    },
    rightBody: {
        textAlign: 'right',
    },
    leftLink: {
        alignSelf: 'flex-start',
    },
    centerLink: {
        alignSelf: 'center',
    },
    rightLink: {
        alignSelf: 'flex-end',
    },
    modelMedia: {
        height: '7rem',
        width: '7rem',
        marginRight: '3rem',
    },
    officeContainer: {
        paddingTop: '8rem',
        paddingBottom: '6rem',
    },
    modelBody: {
        marginBottom: '1rem !important',
    },
    officeMedia: {
        borderRadius: '50%',
    },
    listContainer: {
        marginBottom: '1rem !important',
    },
    modelTitle: {
        marginBottom: '2rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            marginBottom: '1rem',
            marginTop: '1rem',
        },
    },
    lists: {
        listStyle: 'disc',
        paddingInlineStart: '2rem',
        minWidth: '50%',
    },
    listItems: {
        fontSize: '1.8rem',
        lineHeight: 1.5,
        color: v2Colors.core.slate,
        display: 'list-item',
        padding: 0,
        paddingLeft: '0.5rem',
        maxWidth: '45rem',
    },
    leftAlign: {
        textAlign: 'left',
        margin: 'unset',
    },
    rightAlign: {
        textAlign: 'right',
        margin: 'unset',
        float: 'right',
    },
    featureLinks: {
        textDecoration: 'underline',
    },
    boxContainer: {
        padding: '3.2rem',
        border: `1px solid ${v2Colors.border.primary}`,
        backgroundColor: v2Colors.background.lightest,
        borderRadius: '4px',
        boxSizing: 'border-box',
        height: '100%',
        [`@media (max-width: ${breakpoints.md})`]: {
            padding: '2.4rem',
        },
    },
    boxIcon: {
        width: '5rem',
        height: '5rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            width: '4rem',
            height: '4rem',
        },
    },
} as const;
