import { v2Colors, breakpoints, getRgbaColor } from '@web-for-marketing/react-ui';
import type { CtaBannerHeights } from '@models/column';

export const ctaBannerCarouselStyles = {
    parentContainer: {
        position: 'relative',
    },
    bannerContainer: (height: CtaBannerHeights) =>
        ({
            position: 'relative' as const,
            textAlign: 'left',
            height: height === 'tall' ? '60rem' : height === 'medium' ? '50rem' : '40rem',
            backgroundImage: 'linear-gradient(to top, rgba(1, 23, 41, 0.80), rgba(1, 23, 41, 0))',
            [`@media (max-width: ${breakpoints.md})`]: {
                height: '50rem',
                borderRadius: '1rem',
            },
        }) as const,
    bannerContentContainer: {
        height: '100%',
        paddingBottom: '12rem',
        paddingLeft: '6rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingBottom: '4rem',
            paddingLeft: '3rem',
            paddingRight: '3rem',
        },
    },
    bannerImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: '-1',
        [`@media (max-width: ${breakpoints.md})`]: {
            borderRadius: '1rem',
        },
    },
    swipeViewsContainerParent: {
        width: '100%',
        borderRadius: '1rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            overflow: 'visible !important',
            '& > * > div': {
                '&:first-child': {
                    paddingLeft: '0.75rem',
                },
                '&[aria-hidden="true"]': {
                    opacity: 0.75,
                },
            },
        },
    },
    swipeViewsContainer: {
        columnGap: '1.5rem',
    },
    slide: {
        width: 'calc(100% - 1.5rem)',
    },
    paginationControls: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 40,
        right: 40,
        width: '15rem',
    },
    paginationButtons: {
        display: 'flex',
        justifyContent: 'center',
        columnGap: 20,
    },
    iconButton: {
        padding: 0,
        '&:hover, &:active': {
            '& svg': {
                fill: getRgbaColor(v2Colors.core.snow, 0.4),
            },
        },
        '&:disabled svg': {
            '& path': {
                fill: getRgbaColor(v2Colors.core.snow, 0.4),
            },
            '& rect': {
                stroke: 'none',
            },
        },
    },
    leftChevronIcon: {
        transform: 'rotate(180deg)',
    },
    title: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
    description: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
    },
    linkText: {
        textUnderlineOffset: '0.5rem',
        '&:focus, &:hover, &:active': {
            textDecoration: 'underline',
            '& svg': {
                width: '5rem',
            },
        },
    },
    linkArrow: {
        color: v2Colors.core.snow,
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '3rem',
        '& path': {
            strokeWidth: 2,
        },
        transform: 'rotate(180deg)',
        transition: 'width 300ms ease-out',
    },
} as const;
